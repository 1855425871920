$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

.App>div {
    display: flex;
    flex: 1 1;
    justify-content: stretch;
    flex-direction: column;
    flex-basis: 100%;
}

.admin-page {
    &.page-works {
        // position: absolute;
        // min-height: 100%;
        // min-width: 100%;
        // overflow: hidden;
        // background-size: 100% 100%;
        // // background-size: 100vw 100vh;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        display: flex;
        flex: 1;
        justify-content: stretch;
        flex-direction: column;
        flex-basis: 100%;
        color: white;

        .container {
            height: 100%;
            flex: 1;
            display: flex;
        }

        .row {
            width: 100%;
        }

        .col {
            margin-bottom: 200px;
            width: 100%;
        }

        .admin-page__header {
            @media (max-width: $sm) {
                margin: 0 20px;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height:84px;
            margin:0 50px;
            align-self: flex-start;
        }

        .admin-page__logo {
            width: 87px;
            height: 28px;
            display: block;
        }

        .admin-content__container {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-position: right center;
            background-size: contain;
        }

        .admin-content {
            @media (max-width: $lg) {
                padding: 38px 20px;
            }

            padding:38px 50px;
            display: flex;
            flex:1;
            align-self: stretch;
            flex-direction: column;
            background-color: var(--admin-background);

            &__header {
                margin-left: 16px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;
            }

            .h1-bold {
                margin-bottom: 20px;

                @media (max-width: $sm) {
                    font-size: 18px;
                }
            }
        }
    }
}