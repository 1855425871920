.country-page-main-container {
    width: 1920px;
    height: 1080px;
    position: absolute;
    margin-bottom: 0;
}

.country-page-main-content {
    transform-origin: top left;
    width: 1920px;
    height: 1080px;
    position: relative;
    margin-bottom: 0;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(150%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(150%);
    }
}

.page-showing {
    animation: 0.8s ease-out 0s 1 slideInFromLeft;

}

.page-hiding {
    animation: 0.8s ease-in 0s 1 slideOutToLeft;

}

.hided {
    transform: translateX(200%);
}

.page-bg {
    position: relative;
    width: 1277px;
    height: 713px;
    left: 277px;
    top: 150px;

    background: #F6FCFF;
    border-radius: 17px;

    li {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 114.52%;
        color: #042035;

        list-style-type: disc;
        display: list-item;
        align-items: center;
        text-align: left;
    }

    .title {
        position: absolute;
        min-width: 285px;
        height: 53px;
        left: 92px;
        top: 55px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 110.02%;

        display: flex;
        align-items: center;
        text-align: left;
        color: #174C6F;
    }

    .list {
        position: absolute;
        min-width: 455px;
        height: 105px;
        left: 82px;
        top: 105px;

        display: flex;
        align-items: center;
        text-align: left;
    }

    .line {
        position: absolute;
        width: 1103px;
        height: 0px;
        left: 92px;
        top: 221px;
        border: 2px solid #002E6E;
    }

    .main-text {
        position: absolute;
        width: 508px;
        height: 412px;
        left: 687px;
        top: 252px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 156.52%;

        color: #042035;
        align-items: center;
        text-align: left;

        overflow-y: scroll;
    }

    .btn-back {
        position: absolute;
        width: 111.57px;
        height: 111.57px;
        left: 1304px;
        top: 301px;
    }
}

.btn-back-area {
    position: absolute;
    height: 100%;
    width: 100%;
}

.logo {
    position: absolute;
    left: 8.07%;
    right: 80.84%;
    top: 7.87%;
    bottom: 85.74%;
    background-repeat: no-repeat;
}

span.bold {
    font-weight: bold;
}

a {
    color: inherit;
    text-decoration: inherit;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 117%;
}

.photo-main {
    position: absolute;
    width: 537px;
    height: 327px;
    left: 92px;
    top: 265px;

    object-fit: cover;
    filter: drop-shadow(4px 4px 4px rgba(81, 105, 138, 0.55));
    border-radius: 8px;

    cursor: pointer;
}

.flag-container {
    position: absolute;
    width: 175px;
    height: 120px;
    left: 1020px;
    top: 68px;
    overflow: hidden;
}

.flag {
    height: 120px;
    object-fit: cover;
}