@import 'bootstrap';

.App {
  text-align: center;
  text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.logo-image {
  background-image:url('./assets/images/logo-white.png');
}

.background-image {
  background-image:url('./assets/images/background.png');
}

.map-image {
  background-image:url('./assets/images/map.png');
}

.grid-image {
  background-image:url('./assets/images/grid.png');
}

.grid1-image {
  background-image:url('./assets/images/grid1.png');
}

.grid2-image {
  background-image:url('./assets/images/grid2.png');
}

.grid3-image {
  background-image:url('./assets/images/grid3.png');
}

.grid4-image {
  background-image:url('./assets/images/grid4.png');
}

.grid5-image {
  background-image:url('./assets/images/grid5.png');
}

.grid6-image {
  background-image:url('./assets/images/grid6.png');
}

.grid7-image {
  background-image:url('./assets/images/grid7.png');
}

.grid8-image {
  background-image:url('./assets/images/grid8.png');
}

.dot-image {
  background-image:url('./assets/images/dot.png');
}

.dot-big-image {
  background-image:url('./assets/images/dot-big.png');
}

.btn-back-image {
  background-image:url('./assets/images/btn-back.png');
}

.rotate-phone-image {
  background-image:url('./assets/images/rotate_phone.png');
}
