$ms: 1920px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

a {
    pointer-events: all;
}

.background {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    // background-size: 100vw 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rotate-phone-bg {
    @media only screen 
    and (max-device-width : $md) 
    and (orientation : portrait) {        
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;   
        opacity: 0.1;
        pointer-events: none;
    }
    opacity: 1;
    background: none;
}

.rotate-phone {
    @media only screen 
    and (max-device-width : $md) 
    and (orientation : portrait) {
        display: block;
    }
    display: none;
    position: absolute;
    background-repeat: no-repeat;
    left: 50vw;
    top: 50vh;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    margin-top: -64px;
    z-index: 1000;
}

.main-container {
    width: 1920px;
    height: 1080px;
    position: absolute;
    margin-bottom: 0;
}

.main-content {
    transform-origin: top left;
    width: 1920px;
    height: 1080px;
    position: relative;
    margin-bottom: 0;

    pointer-events: none;

    .title {
        position: absolute;
        left: 7.71%;
        right: 43.23%;
        top: 22.31%;
        bottom: 57.31%;

        font-family: 'Golos Text';
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 114.5%;

        display: flex;
        align-items: center;
        text-align: left;
        color: #FFFFFF;
    }

    .subtitle {
        position: absolute;
        left: 8.44%;
        right: 76.2%;
        top: 46.11%;
        bottom: 44.63%;

        font-family: 'Golos Text';
        font-style: normal;
        font-weight: 600;
        font-size: 73.8305px;
        line-height: 135%;

        display: flex;
        align-items: center;
        text-align: left;
        white-space: nowrap;
        color: #FFFFFF;
    }

    ul {
        position: absolute;
        left: 8.59%;
        top: 74.54%;
        bottom: 1.96%;
        right: 6.27%;
        margin-left: 0;
        padding-left: 0;
    }

    .column-list {
        column-count: 6;
    }

    li {
        list-style-type: none;

        display: flex;
        align-items: center;
        text-align: left;
        white-space: nowrap;

        color: #FFFFFF;
    }

    a {
        color: inherit;
        text-decoration: inherit;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 117%;
    }
}

.text-light {
    pointer-events: all;
    animation: text-lighting-off 0.5s forwards;
}

.text-light:hover {
    animation: text-lighting-on 0.5s forwards;
}

.text-light-hover {
    animation: text-lighting-on 0.5s forwards;
}

@keyframes text-lighting-on {
    0% {
        text-shadow: 0 0 0px #ccebff;
    }
    100% {
        text-shadow: 0 0 10px #ccebff;
    }
}

@keyframes text-lighting-off {
    0% {
        text-shadow: 0 0 10px #ccebff;
    }
    100% {
        text-shadow: 0 0 0px #ccebff;
    }
}

.phone-block {
    position: absolute;
    left: 8.59%;
        // right: 15%;
    bottom: 2%;
   
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 117%;
    text-align: left;
    white-space: nowrap;

    color: #FFFFFF;
}

.language-selector {
    position: absolute;
    left: 85%;
    right: 1%;
    // top: 94%;
    bottom: 2%;
    display: flex;
    flex-direction: row;
}

.language-button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 117%;
    align-items: center;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;

    color: #FFFFFF;

    &__first {
        margin-right: 15%;
    }

    &__unactive {
        opacity: 0.3;
    }
}

.map {
    position: absolute;
    left: 14.4%;
    right: 6.81%;
    top: 8.96%;
    bottom: 12.37%;
    width: 1514px;
    height: 851px;
    background-repeat: no-repeat;
}

.grid {
    &__1 {
        position: absolute;
        background-repeat: no-repeat;
        left: 50.25%;
        right: -1.71%;
        top: 39.13%;
        bottom: -33.41%;
        mix-blend-mode: overlay;
        width: 956px;
        height: 658px;
    }

    &__2 {
        position: absolute;
        background-repeat: no-repeat;
        left: 50.21%;
        right: -1.74%;
        top: 48.61%;
        bottom: -33.46%;
        mix-blend-mode: overlay;
        width: 956px;
        height: 556px;
    }

    &__3 {
        position: absolute;
        background-repeat: no-repeat;
        left: -1.22%;
        right: 46.49%;
        top: 39.13%;
        bottom: -33.41%;
        mix-blend-mode: overlay;
        width: 1028px;
        height: 658px;
    }

    &__4 {
        position: absolute;
        background-repeat: no-repeat;
        left: -1.25%;
        right: 46.47%;
        top: 39.08%;
        bottom: -33.46%;
        mix-blend-mode: overlay;
        width: 1028px;
        height: 658px;
    }

    &__5 {
        position: absolute;
        background-repeat: no-repeat;
        left: 50.25%;
        right: -1.71%;
        // top: -44.12%;
        bottom: 51.34%;
        mix-blend-mode: overlay;
        width: 956px;
        height: 526px;
    }

    &__6 {
        position: absolute;
        background-repeat: no-repeat;
        left: 50.21%;
        right: -1.74%;
        // top: -44.17%;
        bottom: 51.3%;
        mix-blend-mode: overlay;
        width: 956px;
        height: 526px;
    }

    &__7 {
        position: absolute;
        background-repeat: no-repeat;
        left: -1.22%;
        right: 46.49%;
        // top: -44.12%;
        bottom: 51.34%;
        mix-blend-mode: overlay;
        width: 1028px;
        height: 526px;
    }

    &__8 {
        position: absolute;
        background-repeat: no-repeat;
        left: -1.25%;
        right: 46.47%;
        // top: -44.17%;
        bottom: 51.3%;
        mix-blend-mode: overlay;
        width: 1028px;
        height: 526px;
    }
}

.logo {
    position: absolute;
    left: 8.07%;
    right: 80.84%;
    top: 7.87%;
    bottom: 85.74%;
    background-repeat: no-repeat;
}

@keyframes showing {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hiding {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes pulse {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(3);
    }

    to {
        transform: scale(0);
    }
}

.element-hided {
    transform: scale(0);
}

.element-showing {
    opacity: 0;
    animation: showing 1.0s forwards;
}

.element-hiding {
    animation: hiding 1.0s forwards;
}

.element-pulse {
    animation: pulse 1.0s ease-in-out;
}

.dot:hover {
    transform: scale(1.5);
}

.dot-hover {
    transform: scale(1.5);
}

.dot {
    background: -webkit-radial-gradient(49.91% 49.91% at 49.96% 49.6%, #FFFFFF 0%, #B3D1CF 5%, #68A59F 10%, #398981 14%, #277E76 16%, #1F665F 21%, #164742 29%, #0E2D2A 38%, #081917 48%, #030B0A 59%, #010202 72%, #000000 96%);
    background: -moz-radial-gradient(49.91% 49.91% at 49.96% 49.6%, #FFFFFF 0%, #B3D1CF 5%, #68A59F 10%, #398981 14%, #277E76 16%, #1F665F 21%, #164742 29%, #0E2D2A 38%, #081917 48%, #030B0A 59%, #010202 72%, #000000 96%);
    background: -o-radial-gradient(49.91% 49.91% at 49.96% 49.6%, #FFFFFF 0%, #B3D1CF 5%, #68A59F 10%, #398981 14%, #277E76 16%, #1F665F 21%, #164742 29%, #0E2D2A 38%, #081917 48%, #030B0A 59%, #010202 72%, #000000 96%);
    background: radial-gradient(49.91% 49.91% at 49.96% 49.6%, #FFFFFF 0%, #B3D1CF 5%, #68A59F 10%, #398981 14%, #277E76 16%, #1F665F 21%, #164742 29%, #0E2D2A 38%, #081917 48%, #030B0A 59%, #010202 72%, #000000 96%);
    background-blend-mode: color-dodge;
    mix-blend-mode: color-dodge;
    position: absolute;
    pointer-events: none;

    z-index: 0;
    transition-duration: 0.5s;
    transition-property: transform;
    transition-timing-function: linear;

    &__abh {
        left: 55%;
        right: 36.04%;
        top: 32.59%;
        bottom: 51.96%;
    }

    &__afg {
        left: 60.19%;
        right: 27.95%;
        top: 36.09%;
        bottom: 44.06%;

    }

    &__ago {
        left: 49.95%;
        right: 41.09%;
        top: 53.89%;
        bottom: 30.66%;

    }

    &__are {
        // ОАЭ
        left: 58.54%;
        right: 32.5%;
        top: 42.69%;
        bottom: 41.86%;
    }

    &__arm {
        left: 55.31%;
        right: 35.73%;
        top: 36.3%;
        bottom: 48.25%;
    }

    &__aze {
        left: 56.88%;
        right: 34.17%;
        top: 35.83%;
        bottom: 48.72%;
    }

    &__ben {
        left: 48.59%;
        right: 42.45%;
        top: 49.35%;
        bottom: 35.2%;
    }

    &__bgd {
        left: 67.03%;
        right: 24.01%;
        top: 42.96%;
        bottom: 41.59%;
    }

    &__bih {
        left: 49.32%;
        right: 41.72%;
        top: 33.33%;
        bottom: 51.22%;
    }

    &__blr {
        left: 52.86%;
        right: 38.18%;
        top: 30.28%;
        bottom: 54.27%;
    }

    &__bwa {
        // Ботсвана
        left: 51.2%;
        right: 39.84%;
        top: 62.98%;
        bottom: 21.57%;
    }

    &__chn {
        left: 67.14%;
        right: 23.91%;
        top: 36.76%;
        bottom: 47.79%;
    }

    &__civ {
        left: 45.42%;
        right: 45.62%;
        top: 50.28%;
        bottom: 34.27%;
    }

    &__cod {
        // ДР Конго
        left: 52.29%;
        right: 38.75%;
        top: 54.44%;
        bottom: 30.1%;
    }

    &__cog {
        // Конго
        left: 51.67%;
        right: 39.37%;
        top: 49.72%;
        bottom: 34.83%;
    }

    &__cub {
        left: 27.81%;
        right: 63.23%;
        top: 43.52%;
        bottom: 41.03%;
    }

    &__dji {
        left: 56.3%;
        right: 34.74%;
        top: 48.06%;
        bottom: 36.49%;
    }

    &__dza {
        // Алжир
        left: 46.56%;
        right: 44.48%;
        top: 40.65%;
        bottom: 43.9%;
    }

    &__egy {
        left: 52.6%;
        right: 38.44%;
        top: 40.65%;
        bottom: 43.9%;
    }

    &__eth {
        left: 54.17%;
        right: 36.87%;
        top: 49.26%;
        bottom: 35.29%;
    }

    &__gha {
        left: 46.72%;
        right: 44.32%;
        top: 48.8%;
        bottom: 35.75%;
    }

    &__gnq {
        // Экваториальная Гвинея
        left: 48.59%;
        right: 42.45%;
        top: 52.35%;
        bottom: 33.2%;
    }

    &__idn {
        left: 73.02%;
        right: 18.02%;
        top: 53.33%;
        bottom: 31.22%;
    }

    &__ind {
        left: 64.43%;
        right: 26.61%;
        top: 45.65%;
        bottom: 38.9%;
    }

    &__ken {
        left: 55.89%;
        right: 35.16%;
        top: 53.52%;
        bottom: 31.03%;
    }

    &__kgz {
        left: 62.5%;
        right: 28.54%;
        top: 32.96%;
        bottom: 51.59%;
    }

    &__lao {
        left: 71.61%;
        right: 19.43%;
        top: 45.74%;
        bottom: 38.81%;
    }

    &__lbn {
        left: 52.86%;
        right: 38.18%;
        top: 37.31%;
        bottom: 47.23%;
    }

    &__lka {
        left: 65.57%;
        right: 25.47%;
        top: 50.28%;
        bottom: 34.27%;
    }

    &__mdg {
        left: 57.66%;
        right: 33.39%;
        top: 61.11%;
        bottom: 23.44%;
    }

    &__mmr {
        // Мьянма
        left: 68.44%;
        right: 22.6%;
        top: 44.91%;
        bottom: 39.64%;
    }

    &__mng {
        left: 70.68%;
        right: 20.36%;
        top: 29.63%;
        bottom: 54.92%;
    }

    &__moz {
        left: 54.48%;
        right: 36.56%;
        top: 61.02%;
        bottom: 23.53%;

    }

    &__nam {
        left: 48.2%;
        right: 39.84%;
        top: 60.98%;
        bottom: 21.57%;
    }

    &__nic {
        left: 24.22%;
        right: 66.82%;
        top: 46.11%;
        bottom: 38.44%;
    }

    &__ost {
        // Южная Осетия
        left: 56.15%;
        right: 34.9%;
        top: 31.39%;
        bottom: 53.16%;
    }

    &__pak {
        left: 62.14%;
        right: 28.91%;
        top: 43.24%;
        bottom: 41.31%;
    }

    &__pse {
        // Палестина
        left: 56.17%;
        right: 38.87%;
        top: 42.17%;
        bottom: 45.38%;
    }

    &__qat {
        // Катар
        left: 56.54%;
        right: 32.5%;
        top: 39.69%;
        bottom: 41.86%;
    }

    &__srb {
        left: 50.52%;
        right: 40.52%;
        top: 34.44%;
        bottom: 50.1%;
    }

    &__syc {
        left: 59.48%;
        right: 31.56%;
        top: 56.94%;
        bottom: 27.6%;
    }

    &__syr {
        left: 54.17%;
        right: 36.87%;
        top: 38.17%;
        bottom: 45.38%;
    }

    &__tha {
        left: 70.26%;
        right: 20.78%;
        top: 46.67%;
        bottom: 37.88%;
    }

    &__tjk {
        left: 60.99%;
        right: 30.05%;
        top: 35.19%;
        bottom: 49.36%;
    }

    &__tkm {
        left: 59.48%;
        right: 31.56%;
        top: 33.33%;
        bottom: 51.22%;
    }

    &__tun {
        left: 48.39%;
        right: 42.66%;
        top: 41.02%;
        bottom: 43.53%;
    }

    &__tza {
        left: 55.89%;
        right: 35.16%;
        top: 53.52%;
        bottom: 31.03%;
    }

    &__uga {
        left: 54.17%;
        right: 36.87%;
        top: 52.31%;
        bottom: 32.23%;
    }

    &__uzb {
        left: 60.47%;
        right: 30.57%;
        top: 31.2%;
        bottom: 53.35%;
    }

    &__vnm {
        left: 71.98%;
        right: 19.06%;
        top: 48.8%;
        bottom: 35.75%;
    }

    &__zaf {
        // ЮАР
        left: 51.2%;
        right: 39.84%;
        top: 66.11%;
        bottom: 18.44%;
    }

    &__zmb {
        left: 51.82%;
        right: 39.22%;
        top: 57.5%;
        bottom: 27.05%;
    }

    &__zwe {
        // Зимбабве
        left: 51.43%;
        right: 37.61%;
        top: 58.98%;
        bottom: 22.57%;
    }

    &__rus {
        left: 62.5%;
        right: 16.15%;
        top: 6.02%;
        bottom: 57.15%;

        background: radial-gradient(49.92% 49.92% at 49.88% 49.8%, #FFFFFF 0%, #B3D1CF 5%, #68A59F 10%, #398981 14%, #277E76 16%, #1F665F 21%, #164742 29%, #0E2D2A 38%, #081917 48%, #030B0A 59%, #010202 72%, #000000 96%);
    }

    &__clickable {
        position: absolute;
        background-color: #000000;
        border-radius: 50px;
        left: 45%;
        right: 45%;
        top: 45%;
        bottom: 45%;
        opacity: 0;
        pointer-events: visible;
    }
}